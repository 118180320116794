<template>
  <div class="d-flex flex-column  flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="save"
      close-after-save
      v-on="$listeners"
      no-history
    >
    </document-toolbar>
    <document-in-tab>
      <v-form
        v-model="form"
        ref="form1"
      >
        <comment
          :text.sync="task.comment"
          :showComment="true"
          :disabled="!task.isNew"
        />
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>
import tabController from '@/tabController'
export default {
  components: {
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Comment: () => import('@/components/comment')
  },
  data () {
    return {
      task: { accountId: this.$route.params.id, comment: null, isNew: true, workItemTypeId: null }
    }
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
    await this.initTask()
  },
  computed: {
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.task.isNew && v) })
      }
    }
  },
  methods: {
    async initTask () {
      this.$emit('document-reset', this.tab)
      this.task.isNew = this.tab.isNew
      if (this.task.isNew) {
        this.task.workItemTypeId = this.tab.typeId
        this.$emit('document-title-change', {
          tab: this.tab, value: `${this.tab.typeName}`
        })
      } else {
        const taskId = this.tab.documentId
        const e = await this.$http().get('/workItem/' + taskId)
        this.task = Object.assign({}, this.task, e.data)

        // this.$emit('document-number', this.task.number)
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.task.number}` })

        this.task.comment = this.task.workItem.closingComment
        if (this.task.comment?.length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
      }
      this.$nextTick(() => {
        this.$emit('document-ready', this.tab)
      })
    },
    async save () {
      if (this.task.isNew) {
        return this.$http().post('/accountWorkItem', this.task)
      }
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  watch: {
    task: tabController.buildPristineWatcher()
  },
  props: {
    tab: Object
  }
}
</script>
